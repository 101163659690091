<template>
    <v-container fluid fill-height style="justify-content: center; padding-top: 70px;">
        <img v-if="!hasAccessToOneApp" src="../assets/images/authentication.svg" alt="authentication"/>
        <project v-else v-for="app in applications" :key="app.name" :app="app"/>
    </v-container>
</template>

<script>
import Project from "../components/Project.component";
import {mapGetters} from "vuex";
import JsonApplication from "@/assets/applications.json";

// TODO: add info -> "Nothing to show"
export default {
    name: 'Home',
    components: {Project},
    computed: {
        ...mapGetters(["user"]),
        applications: function () {
            return JsonApplication;
        },
        hasAccessToOneApp() {
            if(!this.user) return false;
            for (const app of this.applications)
                if (this.user.hasRole(app.group))
                    return true;
            return false;
        }
    }
}
</script>
