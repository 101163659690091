import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: true,
        primary: '#805acb',
        secondary: '#F0F8FF',
        accent: '#8c9eff',
        error: '#b71c1c'
    }
});
