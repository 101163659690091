import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookie';
import Keycloak from 'keycloak-js';
import {SET_USER} from "./store/mutations";

Vue.use(VueCookie);

Vue.config.productionTip = false

let initOptions = {
    url: `//${location.hostname}/auth`, realm: 'fabulhub', clientId: 'frontdesk-app', onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
    if (!auth) {
        window.location.reload();
    } else {

        // load user data
        keycloak.loadUserProfile().then(profile => {

            let payload = {
                username: profile?.username,
                subject: keycloak.subject,
                isConnected: !!keycloak.subject && keycloak.subject !== '',
                isAdmin: keycloak.hasRealmRole('FABULHUB_ADMIN'),
                isModerator: keycloak.hasRealmRole('FABULHUB_MODERATOR'),
                isViewer: keycloak.hasRealmRole('FABULHUB_VIEWER'),
                hasRole: (role) => keycloak.hasRealmRole(role),
            };

            store.commit(SET_USER, payload);
            console.log("User loaded :", payload);

            new Vue({
                router,
                store,
                vuetify,
                render: h => h(App)
            }).$mount('#app')

        }).catch(function (err) {
            console.error(err);
        });
    }

    const refreshTime = 120; // in seconds
    //Token Refresh
    setInterval(() => {
        keycloak.updateToken(refreshTime)
            // .then((refreshed) => console.log('Token refreshed', refreshed))
            .then(() => {})
            .catch(e => {
                console.error('Failed to refresh token :', e);
            });
    }, 6 * 1000);

}).catch(e => {
    console.error("Authenticated Failed :", e);
});