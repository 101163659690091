import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import config from '../../vue.config'

Vue.use(VueRouter)

const baseUrl = config.publicPath;

const routes = [
    {
        path: '',
        redirect: baseUrl + '/'
    },
    {
        path: '/',
        redirect: baseUrl + '/'
    },
    {
        path: baseUrl + '/',
        name: 'Home',
        component: Home
    },
    {
        path: baseUrl + '/logout',
        name: 'Logout',
        component: () => import('../views/Logout'),
    },

    {
        path: baseUrl + '/add',
        name: 'Add',
        component: () => import('../views/videos/EndToEndForm'),
    },

    {
        path: baseUrl + '/playlists/all',
        name: 'Playlists',
        component: () => import('../views/playlists/Playlists'),
    },
    {
        path: baseUrl + '/playlists/new',
        name: 'New playlist',
        component: () => import('../views/playlists/NewPlaylist'),
    },
    {
        path: baseUrl + '/playlists/find/:id',
        name: 'Playlist',
        component: () => import('../views/playlists/Playlist'),
    },


    {
        path: baseUrl + '/videos/all',
        name: 'Videos',
        component: () => import('../views/videos/Videos'),
    },
    {
        path: baseUrl + '/videos/new',
        name: 'New Video',
        component: () => import('../views/videos/NewVideo'),
    },
    {
        path: baseUrl + '/videos/find/:id',
        name: 'Video',
        component: () => import('../views/videos/Video'),
    },

    {
        path: baseUrl + '/pipelines/all',
        name: 'Pipelines',
        component: () => import('../views/pipelines/Pipelines'),
    },
    {
        path: baseUrl + '/pipelines/find/:id',
        name: 'Pipeline',
        component: () => import('../views/pipelines/Pipeline'),
    },

    {
        path: baseUrl + '/pipelines/runners',
        name: 'Runners',
        component: () => import('../views/runners/Runners'),
    },
    {
        path: baseUrl + '/pipelines/queue',
        name: 'Runners',
        component: () => import('../views/runners/Queue'),
    },

    {
        path: baseUrl + '/error/403',
        name: 'Unauthorized',
        component: () => import("../views/Errors/Error403"),
    },
    {
        path: baseUrl + '/error/500',
        name: 'Server down',
        component: () => import("../views/Errors/Error500"),
    },
    {
        path: baseUrl + '/:pathMatch(.*)*',
        name: '404 Not found',
        component: () => import("../views/Errors/Error404"),
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
