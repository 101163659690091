module.exports = {
    publicPath: '/frontdesk',
    devServer: {
        proxy: {
            '/frontdesk-api': {
                target: 'https://www.fabulhub.fr',
                ws: true,
                pathRewrite: {
                    '^/frontdesk-api': '/frontdesk-api'
                },
                cookieDomainRewrite: { "fabulhub.fr": "localhost" },
                withCredentials: true,
            },
            '/pipelines-api': {
                target: 'https://www.fabulhub.fr',
                ws: true,
                pathRewrite: {
                    '^/pipelines-api': '/pipelines-api'
                },
                cookieDomainRewrite: { "fabulhub.fr": "localhost" },
                withCredentials: true,
            }
        }
    }
}