<template>
    <v-navigation-drawer :value="drawer" app>
        <div style="padding-top: 64px"></div>

        <v-divider></v-divider>

        <v-list-item class="px-2" color="#805acb" :to="{ name: 'Home' }">
            <v-list-item-avatar>
                <v-icon dark>mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-title>{{ getUsername }}</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <!-- https://stackoverflow.com/questions/61408985/v-list-group-sub-groups-wont-open-based-on-path-defined-in-group-prop -->
        <v-list dense>
            <!-- ENTIRE list is wrapped in a template -->
            <div v-for="item in routes" :key="item.name">
                <v-list-group v-if="item.children && item.children.filter(c => c.display).length > 0" :group="item.path" :prepend-icon="item.icon" color="#805acb">
                    <!-- this template is for the title of top-level items with children -->
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <!-- this template is for the children/sub-items (2nd level) -->
                    <template v-for="subItem in item.children">
                        <v-list-item v-if="subItem.display" :key="subItem.name" :to="item.path + subItem.path"
                                     class="ml-5">
                            <v-list-item-icon class="mr-4">
                                <v-icon v-text="`${subItem.icon}`"/>
                            </v-list-item-icon>
                            <v-list-item-title class="ml-0">{{ subItem.name }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list-group>
                <v-list-item v-else :key="item.name" :to="item.path">
                    <v-list-item-icon>
                        <v-icon v-text="`${item.icon}`"/>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-0">{{ item.name }}</v-list-item-title>
                </v-list-item>

            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";
import config from "../../vue.config";

const baseUrl = config.publicPath;

export default {
    name: "AdminSideNav",
    props: {
        drawer: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters(["user"]),
        getUsername: function () {
            return !!this.user && !!this.user.username ? this.user.username : "";
        },

        routes() {
            return [

                {
                    name: 'Add Video',
                    path: baseUrl + '/add',
                    icon: 'mdi-movie-open-plus',
                    display: this.user.isModerator,
                },

                {
                    name: 'Playlists',
                    path: baseUrl + '/playlists/all',
                    icon: 'mdi-text-box-outline',
                    display: this.user.isViewer,
                },

                {
                    name: 'Videos',
                    path: baseUrl + '/videos/all',
                    icon: 'mdi-movie-open',
                    display: this.user.isViewer,
                },

                {
                    name: 'Pipelines',
                    path: baseUrl + '/pipelines',
                    icon: 'mdi-cog-outline',
                    children: [
                        {
                            name: 'Pipelines',
                            path: '/all',
                            icon: 'mdi-cog-outline',
                            display: this.user.isModerator,
                        },
                        {
                            name: 'Queue',
                            path: '/queue',
                            icon: 'mdi-cog-outline',
                            display: this.user.isModerator,
                        },
                        {
                            name: 'Runners',
                            path: '/runners',
                            icon: 'mdi-cog-outline',
                            display: this.user.isModerator,
                        },
                    ]
                }
            ]
        }
    },
    methods: {},
}
</script>

<style lang="scss">
a {
    text-decoration: none;
    color: inherit !important;
}

.app-bar {
    &-name {
        padding: 1em;
        color: white;
        font-weight: bolder;

        span {
            margin-left: 2px;
            padding: 1px;
            color: black;
            background-color: #805acb;
            border-radius: 5px;
        }
    }

    &-link-active {
        color: #805acb !important;
    }
}

.margin-left {
    margin-left: 20px;
}
</style>
