<template>
    <v-list-item :key="mykey" @click.prevent="routeTo(link)" color="#805acb">
        <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "ListItem",
    props: {
        mykey: {
            required: true,
            type: String
        },
        link: {
            required: true,
            type: String
        },
        icon: {
            required: true,
            type: String
        },
        title: {
            required: true,
            type: String
        },
        externalLink: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    methods: {
        routeTo(link) {
            if (this.externalLink) {
                window.open(link, "_self");
            } else {
                this.$router.push(link);
            }
        }
    }
}
</script>