<template>
    <v-card v-if="hasAccess" :href="app.url" width="500px" elevation="5" rounded class="ma-2">
        <v-img v-if="app.img.startsWith('//')" style="width: 100%" :src="app.img"></v-img>
        <v-img v-else style="width: 100%" :src="require(`@/assets/images/${app.img}`)"></v-img>

        <v-card-title>{{ app.name }}</v-card-title>

        <v-card-subtitle>{{ app.motto }}</v-card-subtitle>
        <v-card-text>
            <div class="description">{{ app.description }}</div>
            <v-chip-group column>
                <v-chip text-color="#805acb" color="#121212">
                    {{ app.group }}
                </v-chip>
            </v-chip-group>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Project",
    props: {
        app: {
            required: true,
            type: Object
        }
    },
    computed: {
        ...mapGetters(["user"]),
        hasAccess() {
            return this.user.hasRole(this.app.group);
        }
    }
}
</script>

<style lang="scss" scoped>
.description {
    height: 2.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>