<template>
    <v-app>
        <v-app-bar fixed class="app-bar">
<!--            <v-app-bar-nav-icon v-if="isConnected" @click.native.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
            <v-app-bar-nav-icon v-if="isConnected" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="app-bar-name">
                <router-link :to="{ name: 'Home' }">
                    Fabul<span>Hub</span>
                </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu v-if="isConnected" offset-y>

                <template v-slot:activator="{ on, attrs }">
                    <v-btn fab icon color="#805acb" v-bind="attrs" v-on="on">
                        <v-icon large>
                            mdi-account-circle
                        </v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item-group mandatory color="#805acb">
                        <list-item mykey="1" icon="mdi-account-edit" link="/auth/realms/fabulhub/account/#/personal-info" :external-link="true" title="My data"></list-item>
                        <list-item mykey="2" icon="mdi-logout" link="/frontdesk/logout" title="Logout"></list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-app-bar>

        <admin-side-nav v-if="isConnected" :drawer="drawer"></admin-side-nav>
        <v-main style="margin-top: 60px">
            <v-slide-y-transition mode="out-in">
                <router-view/>
            </v-slide-y-transition>
        </v-main>
    </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import ListItem from "@/components/ListItem.component";
import AdminSideNav from "@/components/AdminSideNav.component";

export default {
    name: 'App',
    components: {ListItem, AdminSideNav},
    data: () => ({
        drawer: true,
    }),
    computed: {
        ...mapGetters(["user"]),
        isConnected() {
            return !!this.user && this.user.isConnected;
        }
    },
};
</script>

<style lang="scss">
html {
    overflow-y: auto
}

a {
    text-decoration: none;
    color: inherit !important;
}

.app-bar {
    z-index: 10 !important;

    &-name {
        padding-left: 1em;
        color: white;
        font-weight: bolder;

        span {
            margin-left: 2px;
            padding: 1px;
            color: black;
            background-color: #805acb;
            border-radius: 5px;
        }
    }

    &-link-active {
        color: #805acb !important;
    }
}
</style>
